import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { apiFunctions, urlAPI } from "../../services/api.services";
import ellipse1 from '../../assets/images/Ellipse1.png'
import ellipse2 from '../../assets/images/Ellipse2.png'

function AboutUs() {
  const [employee, setEmployee] = useState([]);
  const [banner, setBanner] = useState({});
  const [portfolio, setPortfolio] = useState([]);

  const getAbout = () => {
    apiFunctions.about((data) => {
      setEmployee(data.employee);
      setBanner(data.about);
      setPortfolio(data.portfolio);
      // console.log(data,'about')
    });
  };

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <div id="who-we-are">
      <div className='w-full h-screen relative'>
        <img
          className='w-full h-full object-cover'
          src={urlAPI+banner.about_image}
        />
        <div className='absolute w-full h-full top-0 left-0 bg-gray-900/30'></div>
        <div className="absolute w-full h-full top-0 left-0">
          <img src={ellipse1} alt="" />
        </div>
        <div className="absolute w-full h-full top-0 left-0">
          <img src={ellipse2} alt="" className="w-full h-screen" />
        </div>
        <div className='absolute top-0 w-full h-full flex flex-col justify-center text-center text-white p-4 amiri-regular'>
          <h1 className='lg:text-5xl text-2xl tracking-widest font-semibold my-5 text-white"'>{banner.about_banner_title}</h1>
        </div>  
      </div>

      <section id="about-detail" className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto py-10 lg:py-20">
        <div className="md:flex justify-between items-start text-center md:text-left">
          <div className="md:w-1/3 text-4xl text-birtua font-semibold tracking-wider">
            {banner.about_title}
          </div>
          <div className="md:w-11/12 lg:w-3/4">
            <div className="uppercase text-md font-semibold text-birtua tracking-wider my-5 md:my-0">
              {banner.about_subtitle}
            </div>
            <div
              className="mt-5 md:text-justify"
              dangerouslySetInnerHTML={{ __html: banner.about_description }}
            ></div>
          </div>
        </div>
      </section>

      <section className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto pb-40">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5">
          {portfolio.map((item) => {
            return <ImagePortfolio key={item.id} img_path={item.image_url} />;
          })}
        </div>
      </section>

      <section id="behind-company" className="py-10 lg:py-20 bg-abu">
        <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto">
          <h1 className="text-2xl md:text-4xl lg:text-6xl text-center mb-10 md:mb-20 text-birtua font-medium tracking-wider">
            BEHIND THE COMPANY
          </h1>
          <div className="grid md:grid-cols-2 items-center gap-10">
            {employee.map((item) => {
              return (
                <CardEmployee
                  key={item.name}
                  fullName={item.name}
                  role={item.role}
                  img_path={item.image_url}
                  instagram={item.instagram}
                  linkedin={item.linkedin}
                  facebook={item.facebook}
                />
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;

export const CardEmployee = ({
  fullName,
  role,
  img_path,
  instagram,
  linkedin,
  facebook,
}) => {
  return (
    <div className="max-w-full bg-white border min-h-[30rem] border-gray-200 rounded-lg shadow">
      <div>
        <img
          className="rounded-t-lg h-96 lg:h-[40rem] w-full object-cover"
          src={urlAPI + img_path}
          alt="card employee"
        />
      </div>
      <div className="text-center">
        <div className="text-lg font-semibold tracking-widest py-2">
          {fullName}
        </div>
        <div className="text-xs font-semibold tracking-widest pb-2">{role}</div>
        <div className="border-t-2 py-2 border-gray-200 flex justify-center gap-5">
          {instagram !== "0" && (
            <a href={instagram}>
              <FaInstagram className="h-6 w-6 hover:text-pink-500" />
            </a>
          )}
          {linkedin !== "0" && (
            <a href={linkedin}>
              <FaLinkedin className="h-6 w-6 hover:text-blue-500" />
            </a>
          )}
          {facebook !== "0" && (
            <a href={facebook}>
              <FaFacebook className="h-6 w-6 hover:text-blue-500" />
            </a>
          )}

        </div>
      </div>
    </div>
  );
};

export const ImagePortfolio = ({ img_path }) => {
  return (
    <div className="min-w-36">
      <img
        src={urlAPI + img_path}
        alt="portfolio"
        className="rounded-2xl object-cover h-80 lg:h-[28rem] w-full"
      />
    </div>
  );
};

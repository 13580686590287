import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";
import { apiFunctions, fetchImagesProduct, urlAPI } from "../../../services/api.services";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { IconQuote, Studio } from "../../../assets/images";
import { ArrowRight, ArrowRightAlt } from "@mui/icons-material";

// import {EffectCoverflow, Pagination, Navigation} from 'swiper';

const useBrand = () => {
  const [brandVal, setBrand] = useState([]);

  useEffect(() => {
    apiFunctions.brand((data) => {
      // console.log(data.data,'brand');
      setBrand(data.data);
    });
  }, []);

  return brandVal;
};

const Swiper1 = () => {
  const brandVal = useBrand();

  return (
    <div>
      {brandVal.length >= 1 && (
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          <SwiperSlide>
            <img src={urlAPI + brandVal[3].image_url} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={urlAPI + brandVal[4].image_url} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={urlAPI + brandVal[5].image_url} alt="slide_image" />
          </SwiperSlide>
          <div className="slider-controller">
            <div className="swiper-button-prev slider-arrow text-white"></div>
            <div className="text-white swiper-button-next slider-arrow "></div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      )}
    </div>
  );
};

const Swiper1_md = () => {
  const brandVal = useBrand();

  return (
    <div>
      {brandVal.length >= 1 && (
        <div className="flex">
          <img
            src={urlAPI + brandVal[3].image_url}
            alt=""
            className="pr-1 w-60 h-80"
          />
          <img
            src={urlAPI + brandVal[4].image_url}
            alt=""
            className="pr-1 w-60 h-80"
          />
          <img
            src={urlAPI + brandVal[5].image_url}
            alt=""
            className="pr-1 w-60 h-80"
          />
        </div>
      )}
    </div>
  );
};
const Swiper12 = () => {
  const brandVal = useBrand();

  return (
    <div>
      {brandVal.length >= 1 && (
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          <SwiperSlide>
            <img src={urlAPI + brandVal[9].image_url} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={urlAPI + brandVal[10].image_url} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={urlAPI + brandVal[11].image_url} alt="slide_image" />
          </SwiperSlide>
          <div className="slider-controller">
            <div className="swiper-button-prev slider-arrow text-white"></div>
            <div className="text-white swiper-button-next slider-arrow "></div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      )}
    </div>
  );
};

const Swiper12_md = () => {
  const brandVal = useBrand();

  return (
    <div>
      {brandVal.length >= 1 && (
        <div className="flex">
          <img
            src={urlAPI + brandVal[9].image_url}
            alt=""
            className="pr-1 w-60 h-80"
          />
          <img
            src={urlAPI + brandVal[10].image_url}
            alt=""
            className="pr-1 w-60 h-80"
          />
          <img
            src={urlAPI + brandVal[11].image_url}
            alt=""
            className="pr-1 w-60 h-80"
          />
        </div>
      )}
    </div>
  );
};
const Swiper13 = () => {
  const brandVal = useBrand();

  return (
    <div>
      {brandVal.length >= 1 && (
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          <SwiperSlide>
            <img src={urlAPI + brandVal[27].image_url} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={urlAPI + brandVal[28].image_url} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={urlAPI + brandVal[29].image_url} alt="slide_image" />
          </SwiperSlide>
          <div className="slider-controller">
            <div className="swiper-button-prev slider-arrow text-white"></div>
            <div className="text-white swiper-button-next slider-arrow "></div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      )}
    </div>
  );
};

const Swiper13_md = () => {
  const brandVal = useBrand();

  return (
    <div>
      {brandVal.length >= 1 && (
        <div className="flex">
          <img
            src={urlAPI + brandVal[27].image_url}
            alt=""
            className="pr-1 w-60 h-80"
          />
          <img
            src={urlAPI + brandVal[28].image_url}
            alt=""
            className="pr-1 w-60 h-80"
          />
          <img
            src={urlAPI + brandVal[29].image_url}
            alt=""
            className="pr-1 w-60 h-80"
          />
        </div>
      )}
    </div>
  );
};

const Swiper2 = () => {
  const brandVal = useBrand();

  return (
    <div>
      {brandVal.length >= 1 && (
        <Swiper
          grabCursor={true}
          centeredSlides={false}
          loop={true}
          slidesPerView={"4"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="swiper_container w-full"
        >
          {brandVal.map(
            (item, index) =>
              item.image_url && (
                <SwiperSlide key={index} className="m-auto p-2 md:p-10">
                  <img
                    src={urlAPI + item.image_url}
                    alt={`slide_image_${index}`}
                    className="object-contain h-10 md:w-full md:h-20 lg:grayscale hover:grayscale-0"
                  />
                </SwiperSlide>
              )
          )}
        </Swiper>
      )}
    </div>
  );
};

const ProductSlider = ({ product }) => {
  const [products, setProduct] = useState([]);
  const [productImages, setProductImages] = useState({});
  // console.log(product,'productyeyey')

  useEffect(() => {
    // Fetch products only once when the component mounts
    apiFunctions.product((data) => {
      setProduct(data.data);
    });
  }, []);

  useEffect(() => {
    const fetchProductImages = async () => {
      try {
        const updatedProductImages = {};

        for (const item of product) {
          const filteredProducts = products.filter((productItem) => productItem.product_type_id === `${item.id}`);
          if (filteredProducts.length > 0) {
            const firstProductId = filteredProducts[0].id;
            const imageData = await fetchImagesProduct(urlAPI, item.id, firstProductId);
            if (imageData) {
              updatedProductImages[imageData.productTypeId] = imageData.imageUrl[0].image_url;
            }
          }
        }
        
        setProductImages((prevImages) => ({
          ...prevImages,
          ...updatedProductImages,
        }));
      } catch (error) {
        console.error('Error fetching product images:', error);
      }
    };
    
    fetchProductImages(); 
  }, [products, product]); 

  return (
    <Swiper
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      loop={true}
      slidesPerView={1}
      modules={[Autoplay]}
      spaceBetween={10}
      breakpoints={{
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      }}
      className="serviceSwiper"
    >
      {product?.map((item) => (
        <SwiperSlide>
          <div
            key={item.id}
            className="border-l-2 border-birmud bg-abu my-5 pl-6 shadow-lg rounded-r-xl m-auto max-w-72 lg:max-w-96"
          >
            <div className="pb-3">
              {item.image_url ? (
                <img
                  src={urlAPI+item.image_url}
                  alt="studio"
                  className="shadow-2xl bg-darkColor rounded-xl w-60 h-40 lg:w-80 lg:h-48 object-cover relative bottom-5 border-[0.125px]"
                />
                ) : (
                <img
                  src={Studio}
                  alt="studio"
                  className="shadow-xl bg-darkColor rounded-xl w-80 h-48 object-cover relative bottom-5"
                />
              )}
            </div>

            <div className="text-3xl font-semibold py-3">{item.name}</div>
            <div
              className="text-darkColor text-sm leading-5 px-auto py-3"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
            <a href={`/pricing/${item.id}`}>
              <p className="text-birmud-prisma font-bold py-3">
                View Product <ArrowRight />
              </p>
            </a>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export const ClientSlider = ({ data }) => {
  return (
    <Swiper
      loop={true}
      slidesPerView={3}
      spaceBetween={5}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      breakpoints={{
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      }}
      className="my-10"
    >
      {data.map((item) => {
        return (
          <SwiperSlide>
            <img
              src={urlAPI + item?.image_url}
              className="h-10 w-24 md:h-14 md:w-32 lg:w-44 object-contain m-auto"
              alt="img-client"
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const IntegrationsSlider = ({ data }) => {
  return (
    <Swiper
      loop={true}
      slidesPerView={3}
      spaceBetween={5}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      breakpoints={{
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      }}
      className="my-10"
    >
      {data?.data.map((item) => {
        return (
          <SwiperSlide>
            <img
              src={urlAPI + item?.image_url}
              className="h-10 w-24 md:h-14 md:w-32 lg:w-44 object-contain m-auto"
              alt="img-client"
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const ReviewSlider = ({ data }) => {
  
  return (
    <Swiper
      loop={true}
      slidesPerView={1}
      spaceBetween={5}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      breakpoints={{
        768: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
    >
      {data?.map((item) => {
        const getInitials = item.name
        .split(" ")
        .slice(0, 2)
        .map((word) => word[0])
        .join("");
        return (
          <SwiperSlide>
            <div className="rounded-lg shadow-xl pt-6 px-6 my-5 mx-4 mb-8">
              <img src={IconQuote} alt="quote" />
              <p
                dangerouslySetInnerHTML={{ __html: item.testimonials }}
                className="font-semibold text-sm pt-4"
              ></p>
              <div className="border-t-2 py-3 mt-3 flex flex-wrap justify-start items-center gap-5 pl-2">
                <div className="h-15 w-15 rounded-full text-white font-bold p-2 bg-birmud-prisma">
                  {getInitials}
                </div>
                <div className="text-sm font-semibold">{item.name}</div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const BlogSlider = ({ data }) => {
  
  return (
    <Swiper
      loop={true}
      slidesPerView={1}
      spaceBetween={20}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      breakpoints={{
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
    >
      {data?.map((item) => {
        
        return (
          <SwiperSlide>
            <a href={`/blog/${item.id}`}>
              <div
                key={item.id}
                className="border-l-2 border-birmud bg-abu py-3 pl-6 shadow-lg rounded-r-xl my-5 text-left"
                style={{ maxHeight: "20rem", overflow: "hidden" }} // Limit height and hide overflow
              >
                <div
                  className="text-lg lg:text-2xl font-semibold py-1 md:py-3 max-h-36 lg:max-h-36"
                  style={{ overflow: "hidden" }} // Truncate text within this div
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
                <p className="text-birmud-prisma font-bold py-1 md:py-3 text-base">
                  Read More <ArrowRightAlt />
                </p>
              </div>
            </a>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const BrandSlider = ({ data }) => {
  return (
    <Swiper
      loop={true}
      slidesPerView={3}
      spaceBetween={5}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      breakpoints={{
        768: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      }}
    >
      {data?.data.map((item) => {
        return (
          <SwiperSlide>
            <img src={urlAPI + item?.image_url} alt={item?.name} className="grayscale hover:grayscale-0 h-10 w-24 md:h-14 md:w-32 lg:w-44 object-contain m-auto"/>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export function SwiperMarquee() {
  const containerRef = useRef(null);
  const swiper = useRef(null);
  const timeout = useRef(null);

  const slides = useMemo(
    () =>
      Array.from(Array(12), () => ({
        url: "https://placehold.co/400x600",
      })),
    []
  );

  const initializeSwiper = useCallback(() => {
    if (!containerRef.current) {
      return null;
    }

    return new Swiper(containerRef.current, {
      loop: true,
      speed: 3000,
      slidesPerView: 5,
      spaceBetween: 4,
    });
  }, []);

  const startMarquee = useCallback(() => {
    swiper.current.slideTo(swiper.current.slides.length, 3000);

    swiper.current.once("transitionEnd", function () {
      swiper.current.slideTo(swiper.current.params.slidesPerView, 0, false);
      timeout.current = setTimeout(() => startMarquee(), 0);
    });
  }, []);

  const stopMarquee = useCallback(() => {
    clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    swiper.current = initializeSwiper();
    startMarquee();

    // swiper.current.el.addEventListener("mouseover", stopSwiper);
    // swiper.current.el.addEventListener("mouseleave", startSwiper);

    // return () => {
    //   swiper.current.el.removeEventListener("mouseover", stopSwiper);
    //   swiper.current.el.removeEventListener("mouseleave", startSwiper);
    // };
  }, [initializeSwiper, startMarquee, stopMarquee]);

  return (
    <div ref={containerRef} className="swiper">
      <div className={"swiper-wrapper"}>
        {slides.map((slide) => (
          <div key={slide.key} className={"swiper-slide"}>
            <img src={slide.url} width="100%" />
          </div>
        ))}
      </div>
    </div>
  );
}

export {
  Swiper1,
  Swiper1_md,
  Swiper12,
  Swiper12_md,
  Swiper13,
  Swiper13_md,
  Swiper2,
  ProductSlider,
  IntegrationsSlider,
  ReviewSlider,
  BrandSlider,
  BlogSlider
};

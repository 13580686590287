import { useState, useEffect } from "react";

import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {
  IconClock,
  IconSecured,
  IconWheel,
  Portfolio,
} from "../../assets/images";
import {
  apiFunctions,
  fetchImagesProduct,
  urlAPI,
} from "../../services/api.services";
import { useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const SinglePricing = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [tabService, setTabService] = useState(0);

  const [landing, setLanding] = useState("");
  const [benefit, setBenefit] = useState("");
  const [product, setProduct] = useState([]);
  const [productType, setType] = useState([]);
  const [contactVal, setContactVal] = useState([]);
  const { id } = useParams();
  // console.log("id", id)
  useEffect(() => {
    apiFunctions.landingpage((data) => {
      setLanding(data?.data);
      // console.log(data.data,'landing')
    });
    apiFunctions.benefit((data) => {
      setBenefit(data?.data);
      // console.log(data?.data,'benefit')
    });
    apiFunctions.product((data) => {
      setProduct(data.data);
      // console.log(data.data,'productVar')
    });
    apiFunctions.productType((data) => {
      // setType(data.data);
      const productTypeID = data.data.filter(
        (item) => item.id === parseInt(id)
      );
      setType(productTypeID);
      // console.log(productTypeID[0], "productType ID");
    });
    apiFunctions.social((data) => {
      setContactVal(data.data);
    });
  }, []);
  const [totalQuantity, setTotalQuantity] = useState(1);
  const [productValue, setproductValue] = useState("");
  const [totalValue, settotalValue] = useState("");

  const [selectedProductId, setSelectedProductId] = useState(null);

  const [productImages, setProductImages] = useState({});
  const [fetchingCompleted, setFetchingCompleted] = useState(false);

  // const productVar = product
  //   .map((item) => item)
  //   .filter((item) => item.product_type_id === id);
  const productVar = product.filter(
    (item) => item.product_type_id === parseInt(id)
  );

  // const productType = typeProduct.map((item) => item).filter((item) => item.id === parseInt(id));

  const productOptions = productVar.filter(
    (product) => product.product_type_id === parseInt(id)
  );

  useEffect(() => {
    if (selectedProductId === null && productVar.length > 0) {
      const initialProductId = productVar[0].id; // Default to the ID of the first product
      setSelectedProductId(initialProductId); // Set initial selected product ID
      setproductValue(productVar[0].name);
      updateTotalValue(productVar[0], totalQuantity);
    }
  }, [selectedProductId, productVar, totalQuantity]);

  const formatPrice = (price) => {
    return `Rp ${price.toLocaleString("id-ID")}`;
  };

  const updateTotalValue = (itemPro, quantity) => {
    // const totalPrice = itemPro.price * quantity;
    let totalPrice;
    productType.length >= 1 && productType[0].is_custom_price == "0"
      ? (totalPrice = itemPro.price * quantity)
      : (totalPrice = 0);
    settotalValue(
      totalPrice > 0 ? formatPrice(totalPrice) : "Harga dari admin"
    );
  };

  const handleDecrease = () => {
    if (totalQuantity > 1) {
      setTotalQuantity(totalQuantity - 1);
      updateTotalValue(
        product.find((item) => item.id === selectedProductId),
        totalQuantity - 1
      );
    }
  };

  const handleIncrease = () => {
    setTotalQuantity(totalQuantity + 1);
    updateTotalValue(
      product.find((item) => item.id === selectedProductId),
      totalQuantity + 1
    );
  };

  useEffect(() => {
    const fetchProductImages = async () => {
      try {
        const updatedProductImages = {};

        if (
          productVar.length > 0 &&
          product.length > 0 &&
          productType.length > 0
        ) {
          const imageFetchPromises = productVar.map(async (productItem) => {
            const { id: productId, product_type_id: productTypeId } =
              productItem;
            const imageData = await fetchImagesProduct(
              urlAPI,
              productTypeId,
              productId
            );

            if (
              imageData &&
              imageData.imageUrl &&
              imageData.imageUrl.length > 0
            ) {
              if (!updatedProductImages[productId]) {
                updatedProductImages[productId] = [];
              }
              updatedProductImages[productId].push(...imageData.imageUrl);
            }
          });

          await Promise.all(imageFetchPromises); // Tunggu semua permintaan selesai
          setProductImages((prevImages) => ({
            ...prevImages,
            ...updatedProductImages,
          }));
          setFetchingCompleted(true);
        }
      } catch (error) {
        console.error("Error fetching product images:", error);
      }
    };

    // Panggil fungsi hanya jika fetching belum selesai
    if (!fetchingCompleted) {
      fetchProductImages();
    }
  }, [urlAPI, id, productVar, product, productType]);

  const handleSelectProduct = (productId) => {
    const selectedProduct = product.find((item) => item.id === productId);
    if (selectedProduct) {
      setSelectedProductId(productId);
      setproductValue(selectedProduct.name);
      setThumbsSwiper(null);
      updateTotalValue(selectedProduct, totalQuantity);
    }
  };

  const submitToWhatsApp = () => {
    const messageContent =
      `Halo Prisma Multimedia, saya ingin membeli/menyewa :\n` +
      `Product: ${productType[0].name} - ${productValue}\n` +
      `Jumlah: ${totalQuantity}\n` +
      `Total: ${totalValue}`;

    const encodedMessage = encodeURIComponent(messageContent);

    const whatsappLink = `${contactVal
      .filter((item) => item.name === "Whatsapp")
      .map((item) => item.url)}?text=${encodedMessage}`;
    window.open(whatsappLink, "_blank");
  };  

  const handleTab = (type) => {
    switch (type) {
      case "detailTab":
        setTabService(0);
        break;
      case "compareTab":
        setTabService(1);
        break;
      default:
        setTabService(0);
        break;
    }
  };
  // console.log(productImages,'ProIMG')

  return (
    <div id="pricing">
      <section id="lists-prices" className="py-10 lg:py-20">
        {productVar.length > 0 ? (
          productVar.map((itemPro) => {
            let totalPrice;
            productType.length >= 1 && productType[0].is_custom_price == "0"
              ? (totalPrice = itemPro.price * totalQuantity)
              : (totalPrice = 0);
            // console.log(totalPrice, "totalPrice in html");
            return (
              <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto">
                {itemPro.id === selectedProductId && (
                  <>
                    <div className="py-4 text-sm text-gray-400 flex">
                      <p>
                        {productType.length >= 1 ? productType[0].name : ""}
                      </p>
                      <IoIosArrowForward className="my-auto mx-2" />
                      <p className="text-darkColor">{itemPro.name}</p>
                    </div>
                    <div className="lg:flex flex-row justify-around gap-10">
                      <div
                        id="swiper-product"
                        className="basis-1/4 md:w-1/2 lg:w-10"
                      >
                        {productImages[selectedProductId] &&
                        productImages[selectedProductId].length > 0 ? (
                          <>
                            <Swiper
                              style={{
                                "--swiper-navigation-color": "#fff",
                                "--swiper-pagination-color": "#fff",
                              }}
                              loop={true}
                              spaceBetween={10}
                              navigation={true}
                              // thumbs={{ swiper: thumbsSwiper }}
                              thumbs={{
                                swiper:
                                  thumbsSwiper && !thumbsSwiper.destroyed
                                    ? thumbsSwiper
                                    : null,
                              }}
                              // thumbs={{ swiper: thumbsSwiper ? thumbsSwiper : null }}
                              modules={[FreeMode, Navigation, Thumbs]}
                              // onSwiper={(swiper) => console.log('Swiper initialized:', swiper)}
                            >
                              {productImages[selectedProductId].map((item) => (
                                <SwiperSlide key={item.id}>
                                  <div className="h-80 rounded-xl bg-cover">
                                    <img
                                      src={urlAPI + item.image_url}
                                      alt=""
                                      className="object-cover w-full h-full rounded-xl"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>

                            <div className="py-2">
                              <Swiper
                                loop={true}
                                spaceBetween={10}
                                slidesPerView={3}
                                freeMode={true}
                                watchSlidesProgress
                                modules={[FreeMode, Thumbs]}
                                onSwiper={setThumbsSwiper}
                              >
                                {productImages[selectedProductId].map(
                                  (item) => (
                                    <SwiperSlide>
                                      <div className="h-20 rounded-xl">
                                        <img
                                          src={urlAPI + item.image_url}
                                          alt=""
                                          className="object-cover w-full h-full rounded-xl"
                                        />
                                      </div>
                                    </SwiperSlide>
                                  )
                                )}
                              </Swiper>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-center flex items-center h-full text-lg text-gray-400 border-[1px] rounded-xl">
                              Image not provided for this variant
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        id="detail-product"
                        className="py-5 px-10 basis-1/2 border-2 rounded-xl my-10 lg:my-0"
                      >
                        <h2 className="pb-5 text-2xl font-semibold">
                          {itemPro.name}
                        </h2>
                        <div className="pb-5">
                          <p className="text-gray-400 py-5">Option :</p>
                          <div className="grid text-sm gap-5 w-auto grid-cols-2 md:grid-cols-3">
                            {productOptions.map((product) => (
                              <div
                                key={product.id}
                                className={`rounded border-2 cursor-pointer ${
                                  product.id === selectedProductId
                                    ? "border-darkColor"
                                    : "hover:border-darkColor"
                                }`}
                                onClick={() => handleSelectProduct(product.id)}
                              >
                                <p className="text-center text-xs md:text-base p-2">
                                  {product.name}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="pb-5">
                          <p className="text-gray-400 py-5">Deskripsi :</p>
                          <div
                            className="text-sm text-justify"
                            dangerouslySetInnerHTML={{
                              __html: itemPro.description,
                            }}
                          />
                        </div>
                      </div>
                      <div
                        id="prices"
                        className="text-center h-72 basis-1/4 border-2 py-5 px-10 rounded-xl"
                      >
                        <h2 className="pb-10 text-3xl font-semibold">
                          {totalPrice > 0
                            ? formatPrice(totalPrice)
                            : "Contact us for prices"}
                        </h2>
                        <div className="flex flew-row justify-center items-center gap-5 mb-10">
                          <div
                            onClick={handleDecrease}
                            className="cursor-pointer"
                          >
                            -
                          </div>
                          <div className="border-2 px-10 rounded-md">
                            {totalQuantity}
                          </div>
                          <div
                            onClick={handleIncrease}
                            className="cursor-pointer"
                          >
                            +
                          </div>
                        </div>

                        <div
                          onClick={submitToWhatsApp}
                          className="cursor-pointer text-white px-10 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg"
                        >
                          Place an order
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })
        ) : (
          <div className="text-center">There are no product variants yet</div>
        )}
      </section>
      <section className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto pb-40">
        <div className="border-l-4 shadow-lg border-birmud px-4 lg:px-10 py-5 lg:py-10 my-10 lg:my-20">
          <div className="lg:flex flex-row justify-center items-center gap-5">
            <div className="basis-4/5 text-2xl lg:text-4xl tracking-wider font-semibold mb-3">
              View details and compare '
              {productType.length >= 1 ? productType[0].name : ""}' variants
            </div>
            <div className="flex gap-2 lg:gap-5">
              <button
                onClick={() => handleTab("detailTab")}
                className={`m-auto basis-1/5 flex flex-col justify-center items-center gap-1 lg:gap-5 p-3 md:p-5 lg:p-10 text-xs lg:text-xl border-abumud shadow-md uppercase rounded-xl ${
                  tabService === 0 ? "bg-[#0E46A340]" : "hover:bg-[#0E46A340]"
                }`}
              >
                <p className="">Detail</p>
              </button>

              <button
                onClick={() => handleTab("compareTab")}
                className={`m-auto basis-1/5 flex flex-col justify-center items-center gap-1 lg:gap-5 p-3 md:p-5 lg:p-10 text-xs lg:text-xl border-abumud shadow-md uppercase rounded-xl ${
                  tabService === 1 ? "bg-[#0E46A340]" : "hover:bg-[#0E46A340]"
                }`}
              >
                <p className="">Compare</p>
              </button>
            </div>
          </div>
        </div>

        {tabService === 0 && (
          <div className="your-detail-tab-content">
            <section className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto">
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
                {productImages[selectedProductId] ? (
                  productImages[selectedProductId].map((item) => {
                    return (
                      <ImagePortfolio key={item.id} img_path={item.image_url} />
                    );
                  })
                ) : (
                  <>
                    <div className="text-center flex items-center h-full text-lg text-gray-400">
                      Image not provided for this variant
                    </div>
                  </>
                )}
              </div>
            </section>
          </div>
        )}
        {tabService === 1 && (
          <div className="your-compare-tab-content">
            <section className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto">
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
                {productOptions.map((product) => {
                  return (
                    <Comparation product={product} Images={productImages} />
                  );
                })}
              </div>
            </section>
          </div>
        )}
      </section>
      <section id="contact-pricing">
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 w-3/5 rounded-xl lg:px-10 py-5 lg:py-14 border-2 border-abumud">
            <div className="lg:flex flex-row justify-between items-center">
              <div className="basis-1/2 text-base md:text-3xl tracking-wider font-semibold text-center lg:text-left mb-3 lg:mb-0">
                {benefit?.text}
              </div>
              <a
                href={contactVal
                  .filter((item) => item.name === "Whatsapp")
                  .map((item) => item.url)}
              >
                <div className="text-white mx-auto lg:mx-0 w-1/2 lg:w-auto lg:px-10 py-2 bg-gradient-to-r from-[#0E46A3] to-[#031736] rounded-lg">
                  <p className="text-center text-xs md:text-base">Contact Us</p>
                </div>
              </a>
            </div>
          </span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <div className="max-w-80 md:max-w-screen-sm lg:max-w-screen-xl mx-auto my-10">
          <div className="lg:flex flex-row justify-center items-center gap-5">
            {benefit?.data?.map((item, index) => (
              <div className="basis-1/3 flex items-center gap-2 md:w-1/2 m-auto pb-3">
                <img
                  src={urlAPI + item[`footer_image_${index + 1}`]}
                  alt="wheel"
                  className="w-12 h-12 object-cover"
                />
                <p className="font-base">
                  {item[`footer_caption_${index + 1}`]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SinglePricing;

export const ImagePortfolio = ({ img_path }) => {
  return (
    <div className="m-auto">
      <img src={urlAPI + img_path} alt="portfolio" className="rounded-2xl " />
    </div>
  );
};

export const Comparation = ({ product, Images }) => {
  // console.log(Images, "img");
  return (
    <div className="text-center">
      <h1 className="font-medium text-xl">{product.name}</h1>
      {Images[product.id] ? (
        <img
          src={urlAPI + Images[product.id][0].image_url}
          alt=""
          className="my-4 rounded-2xl"
        />
      ) : (
        <>
          <div className="text-center flex items-center justify-center h-full text-gray-400">
            Image not provided for this variant
          </div>
        </>
      )}
      <div
        className="text-sm md:text-base text-justify m-auto"
        dangerouslySetInnerHTML={{ __html: product.caption }}
      />
    </div>
  );
};
